import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: "/",
    redirect: '/home', // 重定向
    component: () => import('../views/Main'),
    children: [
      {
        path: '/home',
        name: "home",
        component: () => import('../views/Home'),
      },
      {
        path: "/record",
        name: "record",
        redirect: '/record/travel',
        component: () => import('../views/Record'),
        children: [
          {
            path: "travel",
            name: "travel",
            component: () => import('../views/Travel')
          },
          {
            path: "learn",
            name: "learn",
            component: () => import('../views/Learn')
          },
          {
            path: "edit",
            name: "edit",
            component: () => import('../views/manage/EditArticle')
          },
          {
            path: "detail",
            name: "detail",
            component: () => import('../views/Article')
          },
        ]
      },
      {
        path: "/about",
        name: "about",
        component: () => import('../views/About')
      },
      {
        path: "/letter",
        name: "letter",
        component: () => import('../views/Letter')
      }
    ]
  },
]
//消除路由冲突警告
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  meta: {
    title: "旅行日志"
  }
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title || router.options.meta.title; // 使用当前路由的标题或默认标题  
  if (to.path.includes('record')) {
    store.commit("changeReadingStatus", true);
  } else {
    store.commit("changeReadingStatus", false);
  }
  next();
});
export default router
