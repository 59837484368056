export default {
  baseURL: "http://localhost:8081",
  imBaseURL: "http://localhost:81",
  webURL: "http://localhost",

  // baseURL: "https://poetize.cn/api",
  // imBaseURL: "https://poetize.cn/im",
  // webURL: "https://poetize.cn",

  live2d_path: "https://cdn.jsdelivr.net/gh/stevenjoezhang/live2d-widget@latest/",
  cdnPath: "https://cdn.jsdelivr.net/gh/fghrsh/live2d_api/",
  waifuPath: "/webInfo/getWaifuJson",
  hitokoto: "https://v1.hitokoto.cn",
  shehui: "https://api.oick.cn/yulu/api.php",
  tocbot: "https://cdnjs.cloudflare.com/ajax/libs/tocbot/4.18.2/tocbot.min.js",
  jinrishici: "https://v1.jinrishici.com/all.json",
  random_image: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg?",
  //前后端定义的密钥，AES使用16位
  cryptojs_key: "comeonbabythanks",
  qiniuUrl: "https://upload.qiniup.com",
  qiniuDownload: "$$$$七牛云下载地址，仿照【https://file.poetize.cn/】",

  friendWebName: "POETIZE",
  friendUrl: "https://poetize.cn",
  friendAvatar: "https://s1.ax1x.com/2022/11/10/z9E7X4.jpg",
  friendIntroduction: "这是一个 Vue2 Vue3 与 SpringBoot 结合的产物～",
  friendCover: "https://s1.ax1x.com/2022/11/10/z9VlHs.png",

  favoriteVideo: "$$$$自己找一个视频链接作为百宝箱的封面",

  loveWeiYan: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  loveMessage: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  lovePhoto: "https://s1.ax1x.com/2022/12/04/zsKh5V.jpg",
  loveLike: "https://cdn.cbd.int//hexo-butterfly-envelope/lib/violet.jpg",
  loveSortId: 1,
  loveLabelId: 1,

  friendBG: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  friendLetterTop: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/before.png",
  friendLetterBottom: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/after.png",
  friendLetterBiLi: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/line.png",
  friendLetterMiddle: "https://cdn.cbd.int//hexo-butterfly-envelope/lib/violet.jpg",

  before_color_list: ["#ff4b2b", "#EF794F", "#67C23A", "orange", "rgb(131, 123, 199)", "#23d5ab"],

  tree_hole_color: ["#ee7752", "#e73c7e", "#23a6d5", "#23d5ab", "rgb(131, 123, 199)", "#23d5ab"],

  before_color_1: "black",
  after_color_1: "linear-gradient(45deg, #f43f3b, #ec008c)",

  before_color_2: "rgb(131, 123, 199)",
  after_color_2: "linear-gradient(45deg, #f43f3b, #ec008c)",

  sortColor: ["linear-gradient(to right, #358bff, #15c6ff)",
    "linear-gradient(to right, #18e7ae, #1eebeb)",
    "linear-gradient(to right, #ff6655, #ffbf37)",
    "linear-gradient(120deg, rgba(255, 39, 232, 1) 0%, rgba(255, 128, 0, 1) 100%)",
    "linear-gradient(120deg, rgba(91, 39, 255, 1) 0%, rgba(0, 212, 255, 1) 100%)"
  ],

  pageColor: "#ee7752",
  commentPageColor: "#23d5ab",
  userId: 1,
  source: 0,

  emojiList: ['衰', '鄙视', '再见', '捂嘴', '摸鱼', '奋斗', '白眼', '可怜', '皱眉', '鼓掌', '烦恼', '吐舌', '挖鼻', '委屈', '滑稽', '啊这', '生气', '害羞', '晕', '好色', '流泪', '吐血', '微笑', '酷', '坏笑', '吓', '大兵', '哭笑', '困', '呲牙'],

  agreementList: {
    title: "",
    content: "",
    children: [
      {
        title: "一、协议适用范围",
        children: [
          {
            content:
              "1.1 本协议适用于所有通过【网站名称】（以下简称'本平台'）注册账户并使用其服务的用户（以下简称'用户'）。",
          },
          {
            content:
              "1.2 用户确认已阅读并同意本协议全部内容，方可完成注册流程。",
          },
        ],
      },
      {
        title: "二、用户信息与账户管理",
        children: [
          {
            content:
              "2.1 用户承诺注册时填写的信息真实、完整且符合法律法规要求，包括但不限于手机号、电子邮箱等联系方式。",
          },
          {
            content:
              "2.2 用户需自行保管账户密码及相关验证工具，因未妥善保管导致账户被盗用产生的损失由用户承担。",
          },
          {
            content:
              "2.3 根据【国家/地区名称】法律法规要求，部分功能需完成实名认证，用户应积极配合提供有效身份证明。",
          },
        ],
      },
      {
        title: "三、服务使用规则",
        children: [
          {
            content: "3.1 用户不得利用本平台从事以下活动：",
            children: [
              { content: "传播违法、侵权、暴力、色情或骚扰性内容" },
              { content: "欺骗性交易或刷单行为" },
              { content: "侵犯第三方知识产权或隐私权" },
              { content: "干扰平台正常运营的服务" },
            ],
          },

          {
            content:
              "3.2 用户发布的内容需符合社区准则，本平台有权在不通知用户的情况下删除违规内容。",
          },
          {
            content:
              "3.3 用户需授权本平台收集必要信息以提供服务，具体数据使用规则详见《隐私政策》。",
          },
        ],
      },
      {
        title: "四、知识产权声明",
        children: [
          {
            content:
              "4.1 用户原创内容（如文字、图片、视频等）仍归用户所有，但授予本平台全球范围内非独占、可再授权的使用权，用于平台运营及推广。",
          },
          {
            content:
              "4.2 本平台所有页面设计、商标、技术架构等均受著作权法保护，未经书面许可不得擅自使用。",
          },
        ],
      },
      {
        title: "五、免责声明",
        children: [
          {
            content: "5.1 本平台不对以下情形承担责任：",
            children: [
              { content: "用户自行上传或第三方提供的内容合法性" },
              { content: "不可抗力（如网络中断、自然灾害）导致的服务中断" },
              { content: "用户设备故障或操作失误造成的损失" },
            ],
          },
        ],
      },
      {
        title: "六、协议变更与终止",
        children: [
          {
            content:
              "6.1 本平台有权随时更新协议内容，更新后将通过站内公告或邮件通知用户，继续使用视为接受变更。",
          },
          {
            content:
              "6.2 用户可随时注销账户，账户终止后本平台将在法定期限内保留必要数据用于纠纷处理，其余信息将删除。",
          },
          {
            content:
              "6.3 若用户违反本协议，本平台有权暂停或终止其账户权限。",
          },
        ],
      },
      {
        title: "七、法律适用与争议解决",
        children: [
          {
            content:
              "7.1 本协议受【国家/地区名称】法律管辖，争议提交【仲裁机构/法院名称】解决。",
          },
          {
            content:
              "7.2 用户同意接受本平台基于算法作出的服务决策（如内容推荐、信用评估等）。",
          },
        ],
      },
      {
        title: "八、其他条款",
        children: [
          {
            content:
              "8.1 本协议自用户首次登录之日起生效，有效期至用户账户注销或协议修订生效之日。",
          },
          {
            content:
              "8.2 未尽事宜可通过【邮箱/联系方式】进行咨询，本平台保留最终解释权。",
          },
        ],
      },
    ],
  },
}
