<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style  lang="less" scoped>

@import url('@/assets/theme/common.css');

</style>
